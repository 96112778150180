<template>
    <div :class="$style['token-dialog']">
        <div :class="$style['token-dialog__menu-container']">
            <div
                v-if="auth0IsAuthenticated && userAvatar"
                @click="toggleMenu=!toggleMenu"
            >
                <mds-profile-image
                    id="token-dialog-user-button"
                    :class="$style['token-dialog__user-avatar']"
                    :src="userAvatar"
                    alt="User Photo"
                    width="35"
                ></mds-profile-image>
            </div>
            <mds-button
                v-else
                id="token-dialog-user-button"
                variation="icon-only"
                icon="person"
                type="button"
                @click="toggleMenu=!toggleMenu"
            >User</mds-button>
            <mds-popover
                v-model="toggleMenu"
                triggered-by="token-dialog-user-button"
                :position="['bottom-left']"
                :class="$style['token-dialog__popover']"
                width="200px">
                <template v-if="auth0IsAuthenticated">
                    <mds-section bold :size="8" border-position="bottom" :title="userEmail">
                        <mds-button variation="flat" text="Sign Out" @click.native="toggleMenu = false, logout()"></mds-button>
                    </mds-section>
                </template>
                <mds-list-group v-else-if="ENABLE_USER_MANAGEMENT">
                    <mds-list-group-item @click.native="signInUser">Sign In</mds-list-group-item>
                    <mds-list-group-item @click.native="onSignUpMenuItemClicked">Sign Up</mds-list-group-item>
                </mds-list-group>
                <mds-button variation="primary" text="Contact Us" :class="$style['dark-button']"
                    href="https://www.morningstar.com/products/direct-web-services/contact"
                    target="_blank"></mds-button>
            </mds-popover>
        </div>

        <div :class="$style['token-dialog__button-container']">
            <mds-button variation="primary" text="Contact Us" :class="$style['dark-button']"
                href="https://www.morningstar.com/products/direct-web-services/contact"
                target="_blank"></mds-button>
            <mds-button v-if="auth0IsAuthenticated" variation="flat" text="Sign Out" @click="logout"></mds-button>
            <mds-button-container v-else-if="ENABLE_USER_MANAGEMENT" right-aligned>
                <mds-button variation="flat" text="Sign Up" @click="toggle=!toggle"></mds-button>
                <mds-button variation="flat" icon="person" text="Sign In" @click="signInUser"></mds-button>
            </mds-button-container>
        </div>

        <mds-modal title="Sign Up" v-model="toggle" :width="'600px'" action-required>
            <sign-up @signIn="signInUser"></sign-up>
            <template v-slot:mds-modal-actions>
                <mds-button-container right-aligned>
                    <mds-button @click="toggle = false" variation="secondary">Cancel</mds-button>
                </mds-button-container>
            </template>
        </mds-modal>

    </div>
</template>

<script>

import { MdsButton, MdsButtonContainer } from '@mds/button';
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group';
import MdsModal from '@mds/modal';
import MdsPopover from '@mds/popover';
import MdsProfileImage from '@mds/profile-image';
import MdsSection from '@mds/section';
import SignUp from './sign-up.vue';
import TokenStorageService from '../utils/token-storage-service.js';
import { ENABLE_USER_MANAGEMENT } from '../data/constants';

export default {
name: 'token-dialog',
    components: {
        MdsButton,
        MdsButtonContainer,
        MdsListGroup,
        MdsListGroupItem,
        MdsModal,
        MdsPopover,
        MdsProfileImage,
        MdsSection,
        SignUp,
    },
    data() {
        return {
            toggle: false,
            tokenString: '',
            auth0IsAuthenticated: false,
            toggleMenu: false,
            ENABLE_USER_MANAGEMENT: ENABLE_USER_MANAGEMENT,
        };
    },
    created() {
        this.auth0IsAuthenticated = TokenStorageService.GetAuth0Token() != '';
        TokenStorageService.StartTimers();
        TokenStorageService.Subscribe(TokenStorageService.TOKEN_EVENT_TYPE.TOKEN_LOGOFF, this.handleLogoutAuthentication);
        TokenStorageService.Subscribe(TokenStorageService.TOKEN_EVENT_TYPE.TOKEN_CHANGED, this.handleTokenChanged);
    },
    computed: {
        parsedToken() {
            const auth0Token = TokenStorageService.GetAuth0Token();
            return auth0Token ? TokenStorageService.parseToken(auth0Token) : null;
        },
        userAvatar() {
            return this.parsedToken?.picture;
        },
        userEmail() {
            return this.parsedToken?.email;
        },
    },
    methods: {
        handleTokenChanged() {
            this.auth0IsAuthenticated = TokenStorageService.GetAuth0Token() != '';
        },
        handleLogoutAuthentication() {
            this.auth0IsAuthenticated = false;
        },
        logout() {
            TokenStorageService.Auth0Logout();
            this.$auth.logout();
        },
        signInUser() {
            this.$auth.loginWithRedirect({ appState: { targetUrl: window.location.pathname } });
        },
        onSignUpMenuItemClicked() {
            this.toggle = !this.toggle;
            this.toggleMenu = !this.toggleMenu;
        },
    },
}
</script>
<style module lang="scss">
    @import '../style/components/token-dialog';
</style>