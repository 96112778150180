<template>
    <div :class="$style['partners-landing-page']">
        <mds-layout-grid class="mds-doc-typography___devsite">
            <mds-row>
                <mds-col :cols="8">
                    <h1 :class="$style['partners-landing-page__title']">Partners</h1>
                    <item-description :description="itemsData.overview"></item-description>
                </mds-col>
                <mds-col :cols="4">
                    <img :class="$style['partners-landing-page__partner-img']" :src="imageUrl" />
                </mds-col>
            </mds-row>
            <template v-if="itemsData.items">
              <mds-row :class="$style['partners-landing-page__row--containing-cards']">
                <mds-col cols="12" cols-at-s="6" cols-at-m="4"
                         v-for="details in itemsData.items"
                         :key="details.name">
                  <mds-card
                    supplemental-content-first
                    :title="details.title || ''"
                    :caption="details.summary"
                    full-bleed
                  >
                    <template #mds-card-supplemental-content>
                      <div :class="$style['partners-landing-page__card-image']" v-html="details.image"></div>
                    </template>
                    <template #mds-card-action>
                      <mds-button
                        variation="primary"
                        type="button"
                        :to="getUrlPath(details.path)"
                      >{{ details.buttonText }}</mds-button>
                    </template>
                  </mds-card>
                </mds-col>
              </mds-row>
            </template>
            <mds-row :class="$style['partners-landing-page__footer-row']">
              <mds-col>
                <div :class="$style['partners-landing-page__footer']">
                  <item-description :description="itemsData.contact?.displayText"></item-description>
                </div>
              </mds-col>
            </mds-row>
        </mds-layout-grid>
    </div>
</template>
<script>
import axios from 'axios';
import ItemDescription from './items/item-description.vue';
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import { MdsButton } from '@mds/button';
import MdsCard from '@mds/card';
import { HOME_URL, PARTNERS_FILE } from '../data/constants';

export default {
    name: 'partners',
    components: {
        ItemDescription,
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        MdsButton,
        MdsCard
    },
    data() {
        return {
            itemsData: [],
        };
    },
    computed: {
        imageUrl() {
            return `${process.env.VUE_APP_SHARED_HIDDEN_FOLDER_URL}/partners-graphic.svg`;
        },
    },
    methods: {
        async fetchItemsData() {
            const jsonFileResponse = await axios.get(PARTNERS_FILE);
            this.itemsData = jsonFileResponse.data;
        },
        getUrlPath(path) {
          return `${HOME_URL}/partners/${path}`;
        }
    },
    async created() {
        await this.fetchItemsData();
    },
}
</script>
<style lang="scss" module>
@import '@mds/typography';

.partners-landing-page {
  padding: $mds-space-4-x $mds-space-4-x 0;
  margin: auto;
  max-width: 1347px;

  &__title {
    @include mds-level-1-heading();

    color: $mds-text-color-primary;
    margin: 0 0 $mds-space-3-x;
  }

  .mds-card {
    margin: $mds-space-2-x 0;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 45px; /* space for absolutely positioned button (29px button height + 16px card padding) */
    height: calc(100% - $mds-space-4-x); /* 100% - (margin-top + margin-bottom) */

    &__title {
      @include mds-level-5-heading($bold: true);
    }

    &__supplemental-content {
      align-items: center;
      display: flex;
      justify-content: left;
      height: 190px;
      padding: $mds-space-2-x $mds-space-2-x 0;

      svg {
        height: 190px;
        width: 190px;
      }
    }

    &__action {
      position: absolute;
      bottom: $mds-space-2-x; /* Card padding */
    }
  }

  &__footer {
    text-align: center;
    color: $mds-text-color-primary-on-dark;
    padding: $mds-space-2-x;

    &-row {
      background-color: $mds-interactive-color-secondary-active;
    }

    & &-title {
      @include mds-level-1-heading();
      color: $mds-text-color-primary-on-dark;
      margin: $mds-space-1-x;
    }

    & &-text {
      @include mds-level-4-heading();
      color: $mds-text-color-primary-on-dark;

      &:before {
        content: none;
      }
    }

    .mds-button {
      margin: $mds-space-3-x 0 $mds-space-1-x;
    }

    .btn-link {
      margin: 0 auto;
    }
  }
}
</style>