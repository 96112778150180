<template>
    <mds-list-group-item
      :text="titleText"
      :active="$route.path == routeString"
      :to="routeString"
    >
    </mds-list-group-item>
</template>
<script>
import { MdsListGroupItem } from '@mds/list-group';
import NavItemMixin from './nav-item-mixin';
import RouteProcessor from '../../utils/route-processor.js';
import { HOME_URL } from '../../data/constants';

export default {
  name: 'nav-versioned-single-item',
  props: {
    contentType: String,
    section: String,
    versions: {
      type: Array,
      default: () => [
        {
          version: String,
          url: String,
          type: String
        }
      ]
    },
  },
  components: {
    MdsListGroupItem,
  },
  mixins: [NavItemMixin],
  computed: {
    routeString() {
      // It would be more performant to create this once on created(), but created()
      // doesn't get called when the nav data changes, but the same item type is in
      // the same place in the new nav data. But the props do change. So it's either
      // generate the route string dynamically on demand, or watch the props.
      return `${HOME_URL}/${this.contentType}/${this.section}/${RouteProcessor.getAlias(this.text)}`;
    },
    version() {
      // Removed version dropdown from UI and displaying content of the last version added
      return this.versions[0].version;
    },
  },
};
</script>
