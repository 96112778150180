<template>
  <mds-list-group-item v-if="showSingleLink"
    :text="titleText"
    :active="isSingleLinkActive"
    @click.native="itemClicked"
  >
  </mds-list-group-item>
  <mds-list-group-item
    v-else
    has-collapsible-child
    :collapsible="true"
    :expanded="toggleActive"
    :text="text"
    @click.native="subListClicked"
  >
    <template #mds-list-item-children>
      <nav-single-item
        v-for="(sub, index) in selectedSublist"
        :key="index"
        :text="sub.text"
        :baseRoute="routeString"
      />
    </template>
  </mds-list-group-item>
</template>

<script>
import { MdsListGroupItem } from '@mds/list-group';
import NavItemMixin from './nav-item-mixin';
import NavSingleItem from './nav-single-item.vue';
import RouteProcessor from '../../utils/route-processor.js';
import { CONTENT_TYPE, REGION } from '../../data/constants';

export default {
  name: 'nav-versioned-sublist',
  components: {
    MdsListGroupItem,
    NavSingleItem,
  },
  mixins: [NavItemMixin],
  props: {
    baseRoute: String,
    versions: Array,
    contentType: String, // This is the path base, e.g. 'components' or 'apis' - required
    section: String,
  },
  computed: {
    showSingleLink() {
      /* For APIs and Components, we would be displaying just the list heading
          Sub-list items would be instead displayed in tabs
          Clicking on list heading would display first child items content
      */
      return [CONTENT_TYPE.APIS, CONTENT_TYPE.COMPONENTS].indexOf(this.contentType) > -1 || this.versions[0].sublist.length === 1;
    },
    isSingleLinkActive() {
      return this.showSingleLink && this.$route.path.includes(this.baseRouteUrl + '/');
    },
    baseRouteUrl() {
      if (this.region && this.region !== REGION.ALL) {
        return this.baseRoute;
      }
      return `${this.baseRoute}/${RouteProcessor.getAlias(this.text)}`;
    },
    firstSubListItemRouteString() {
      return `${this.baseRouteUrl}/${RouteProcessor.getAlias(this.selectedSublist[0].text)}`;
    },
    routeString() {
      if (this.showSingleLink) {
          return this.firstSubListItemRouteString;
      }
      // It would be more performant to create this once on created(), but created()
      // doesn't get called when the nav data changes, but the same item type is in
      // the same place in the new nav data. But the props do change. So it's either
      // generate the route string dynamically on demand, or watch the props.
      return this.baseRouteUrl;
    },
    selectedSublist() {
      // Removed version dropdown from UI and displaying content of the first version
      return this.versions[0].sublist;
    },
    toggleActive() {
      return this.$route.path.startsWith(this.routeString);
    },
  },
  methods: {
    itemClicked() {
      this.$router.push({ path: this.routeString });
    },
    subListClicked(event) {
      if (event.target.closest('li') === event.currentTarget) {
        window.setTimeout(() => {
          this.$router.push({ path: this.firstSubListItemRouteString });
        });
      }
    },
  },
}
</script>
