import Vue from 'vue'
import App from './App.vue'
import router from './router'

import { Auth0Plugin } from "./auth";

const envs = require("../auth_config.json");
let actualEnvironment;
switch(process.env.NODE_ENV) {
  case "prod":
    actualEnvironment = "prod";
    break;
  default:
    actualEnvironment = "dev";
    break;
}
const domain = envs[actualEnvironment].domain;
const clientId = envs[actualEnvironment].clientId;

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
