export default {
    name: 'nav-item-mixin',
    props: {
        region: String,
        text: String,
    },
    computed: {
        titleText() {
            return this.text.replace(/ *\([^)]*\) */g, ""); // Remove text from between parentheses
        },
    },
}
