<template>
  <nav :aria-label="sectionText + ' navigation section'">
    <mds-list-group class="nav-section">
      <template v-for="item in itemsGroupedByRegion">
        <template v-if="item[REGION.ALL]">
          <nav-section-item
            :key="item[REGION.ALL].text"
            :baseRoute="baseRoute"
            :contentType="contentType"
            :region="REGION.ALL"
            :section="sectionText"
            :item="item[REGION.ALL]"
          />
        </template>

        <mds-list-group-item v-else
          :key="item.displayText"
          has-collapsible-child
          :collapsible="true"
          :expanded="activeSubListTitle === item.displayText"
          :text="item.displayText"
          @click.native="subListClicked"
        >
          <template #mds-list-item-children>
            <template v-for="region in REGION">
              <template v-if="region !== REGION.ALL && item[region]">
                <nav-section-item
                  :key="item[region].text"
                  :baseRoute="getBaseRoute(item[region])"
                  :contentType="contentType"
                  :region="region"
                  :section="sectionText"
                  :item="item[region]"
                />
              </template>
            </template>
          </template>
        </mds-list-group-item>
      </template>
    </mds-list-group>
  </nav>
</template>

<script>
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group';
import NavSectionItem from './nav-section-item.vue';
import { HOME_URL, REGION, SECTION_ITEM_TYPE  } from '../../data/constants';
import RouteProcessor from '../../utils/route-processor.js';

export default {
  name: 'nav-section',
  props: {
    initialItems: Array,
    contentType: String,
    section: String
  },
  data() {
    return {
      activeSubListTitle: '',
    }
  },
  components: {
    MdsListGroup,
    MdsListGroupItem,
    NavSectionItem,
  },
  created() {
    // Allow the constant to be used in the template
    this.REGION = REGION;
    this.SECTION_ITEM_TYPE = SECTION_ITEM_TYPE;
    this.updateToggleActiveList();
  },
  computed: {
    baseRoute() {
      return `${HOME_URL}/${this.contentType}/${this.sectionText}`;
    },
    itemsGroupedByRegion() {
      const mergedItems = [];
      let index;
      for (index = 0; index < this.initialItems.length; index++) {
        let item = this.initialItems[index];
        let itemRegion = REGION.ALL;
        let itemToCheck;
        let sameNameItem;
        let subList;
        item.displayText = item.text.replace(/ *\([^)]*\) */g, ""); // Remove text from between parentheses
        switch(item.sectionItemType) {
          case SECTION_ITEM_TYPE.SINGLE_LINK:
          case SECTION_ITEM_TYPE.VERSIONED_SINGLE_LINK:
            itemToCheck = item;
            if (item.versions) {
              itemToCheck = item.versions[0];
            }
            if (itemToCheck.regions) {
              itemRegion = itemToCheck.regions[0];
            }
            break;
          case SECTION_ITEM_TYPE.VERSIONED_SUBLIST:
          case SECTION_ITEM_TYPE.SUBLIST:
            subList = item.sublist;
            if (item.versions) {
              subList = item.versions[0].sublist;
            }
            for (let i = subList.length - 1; i >= 0; i--) {
              if (subList[i].regions) {
                itemRegion = subList[i].regions[0];
                break;
              }
            }
            break;
          default: break;
        }
        sameNameItem = mergedItems.find((mergedItem) => mergedItem.displayText === item.displayText);
        if (sameNameItem) {
          sameNameItem[itemRegion] = item;
        } else {
          mergedItems.push({
            displayText: item.displayText,
            [itemRegion]: item,
          });
        }
      }
      return mergedItems;
    },
    sectionText() {
      return RouteProcessor.getAlias(this.section);
    },
  },
  methods: {
    getBaseRoute(item) {
      let baseRouteForItem = this.baseRoute;
      if ([SECTION_ITEM_TYPE.SUBLIST, SECTION_ITEM_TYPE.VERSIONED_SUBLIST].indexOf(item.sectionItemType) > -1) {
        baseRouteForItem = `${this.baseRoute}/${RouteProcessor.getAlias(item.text)}`;
      }
      return baseRouteForItem;
    },
    subListClicked(clickEvent) {
      const currentTarget = clickEvent.currentTarget;
      if (clickEvent.target.closest('li') === currentTarget) {
        const label = currentTarget.querySelector('.mds-list-group-item__text___devsite').textContent.trim();
        const itemGroup = this.itemsGroupedByRegion.find(itemGroup => itemGroup.displayText === label);
        const firstRegion = Object.values(this.REGION).find(regionValue => itemGroup[regionValue]);
        const firstSubListItemRouteString = `${this.getBaseRoute(itemGroup[firstRegion])}/${RouteProcessor.getAlias(itemGroup[firstRegion].sublist[0].text)}`;
        window.setTimeout(() => {
          this.$router.push({ path: firstSubListItemRouteString });
        });
      }
    },
    updateToggleActiveList(routeObj) {
      routeObj = routeObj || this.$route;
      this.activeSubListTitle = '';
      this.itemsGroupedByRegion.forEach((itemGroup) => {
        // if (Object.values(this.REGION).find(regionValue => regionValue !== REGION.ALL && itemGroup[regionKey] )) {
        if (itemGroup[this.REGION.EMEA] || itemGroup[this.REGION.NA]) {
          if (routeObj.path.startsWith(`${this.baseRoute}/${RouteProcessor.getAlias(itemGroup.displayText)}`)) {
            this.activeSubListTitle = itemGroup.displayText;
          }
        }
      });
    },
  },
  watch: {
    $route: function(to) {
      this.updateToggleActiveList(to);
    },
  },
};
</script>
