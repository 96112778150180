import axios from "axios";

export class UserRegistrationService {

    EVENT_TYPE = {
        USER_REGISTERED_SUCCESSFULLY: 'REGISTERED',
        USER_REGISTRATION_FAILED: 'FAILED',
    };

    userRegistrationApiUrl = process.env.VUE_APP_USER_REGISTRATION_URL;

    observers = {};

    constructor() {
        for (let eventType of Object.values(this.EVENT_TYPE)) {
            this.observers[eventType] = [];
        }
    }

    Subscribe(eventType, f) {
        if(Object.values(this.EVENT_TYPE).includes(eventType)) {
            this.observers[eventType].push(f);
        }
    }

    Unsubscribe(eventType, f) {
        if(Object.values(this.EVENT_TYPE).includes(eventType)) {
            this.observers = this.observers.filter(subscriber => subscriber !== f);
        }
    }

    RegisterUser(userData) {
        this.registerUser(userData);
    }

    // Internal
    notify(eventType, eventDetails) {
        this.observers[eventType].forEach(observer => observer(eventDetails));
    }

    // Internal
    registerUser(userData) {
        axios.post(this.userRegistrationApiUrl, userData)
            .then((response) => {
                this.userRegistrationSuccessCallback(response);
            }, (err) => {
                console.log("Error registering user:", err);
                this.notify(this.EVENT_TYPE.USER_REGISTRATION_FAILED);
            });
    }

    // Internal
    userRegistrationSuccessCallback(response) {
        this.notify(this.EVENT_TYPE.USER_REGISTERED_SUCCESSFULLY, response.data);
    }
}

// Exporting an instance rather than the class definition makes this a singleton (needed for observer pattern)
export default new UserRegistrationService();