<template>
    <div :class="$style['use-cases']">
        <div :class="$style['use-cases__back-btn']">
            <mds-button
                v-show="caseId"
                variation="flat"
                icon="caret-left"
                :to="HOME_URL + '/use-cases'"
            >Back to Use Cases</mds-button>
        </div>
        <mds-layout-grid class="mds-doc-typography___devsite">
            <template v-if="caseId && useCaseDetails">
                <mds-row :class="[$style['use-cases__row--with-logo'], $style['use-cases__row--with-spacer']]">
                    <mds-col cols="12" cols-at-m="8">
                        <h1 :class="$style['use-cases__title']">{{ useCaseDetails.name }}</h1>
                        <item-description :description="useCaseDetails.description" :class="$style['use-cases__overview']"></item-description>
                    </mds-col>
                    <mds-col cols="12" cols-at-m="4">
                        <img class="mds-doc-homepage__hero-body-image" :src="getImageUrl(useCaseDetails.image)" alt="home page banner">
                    </mds-col>
                </mds-row>
                <mds-row
                    v-for="(rowDetails, index) in useCaseDetails.descriptionRows"
                    :key="index"
                    :class="$style['use-cases__row--with-spacer']">
                    <mds-col v-for="(colDetails, index2) in rowDetails" :key="index2" cols="12" :cols-at-m="colDetails.cols">
                        <mds-layout-grid v-if="colDetails.image">
                            <mds-row>
                                <mds-col cols="12" cols-at-m="4">
                                    <img :src="getImageUrl(colDetails.image)" width="100%">
                                </mds-col>
                                <mds-col cols="12" cols-at-m="8">
                                    <item-description :description="colDetails.markdown"></item-description>
                                </mds-col>
                            </mds-row>
                        </mds-layout-grid>
                        <item-description v-else :description="colDetails.markdown"></item-description>
                    </mds-col>
                </mds-row>
                <mds-row v-if="useCaseDetails.slideshow" :class="$style['carousel-container']">
                    <mds-col cols="12">
                        <h3 v-if="useCaseDetails.slideshow.title">{{ useCaseDetails.slideshow.title }}</h3>
                        <media-carousel :slides="useCaseDetails.slideshow.slides"></media-carousel>
                    </mds-col>
                </mds-row>
            </template>
            <page-not-found v-else-if="caseId" />
            <mds-row v-else>
                <mds-col cols="12" cols-at-m="6" align-vertical="flex-end">
                    <h1 :class="$style['use-cases__title']">Use Cases</h1>
                    <item-description :description="itemsData.overview" :class="$style['use-cases__overview']"></item-description>
                </mds-col>
                <mds-col cols="12" cols-at-m="6">
                    <img class="mds-doc-homepage__hero-body-image" src="../images/banner-homepage.png" alt="home page banner">
                </mds-col>
            </mds-row>
            <template v-if="!caseId && itemsData.items">
                <mds-row :class="$style['use-cases__row--containing-cards']">
                    <mds-col cols="12" cols-at-s="6" cols-at-m="4"
                            v-for="details in itemsData.items"
                            :key="details.name">
                        <mds-card
                            :title="details.title || ''"
                            :label="details.category"
                            supplemental-content-first
                            full-bleed
                        >
                            <template #mds-card-supplemental-content>
                                <img
                                    :src="getImageUrl(details.image)"
                                    alt
                                >
                            </template>
                            <template #mds-card-action>
                                <mds-button
                                    variation="secondary"
                                    type="button"
                                    :to="getUrlPath(details.name)"
                                >Learn More</mds-button>
                            </template>
                        </mds-card>
                    </mds-col>
                </mds-row>
            </template>
            <mds-row v-if="itemsData.contact?.displayText" :class="$style['use-cases__footer-row']">
                <mds-col>
                    <div :class="$style['use-cases__footer']">
                        <item-description :description="itemsData.contact?.displayText"></item-description>
                    </div>
                </mds-col>
            </mds-row>
        </mds-layout-grid>
    </div>
</template>
<script>
import axios from 'axios';
import { HOME_URL, USE_CASES_FILE } from '../data/constants';
import ItemDescription from './items/item-description.vue';
import { MdsButton } from '@mds/button';
import MdsCard from '@mds/card';
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import MediaCarousel from './media-carousel.vue';
import PageNotFound from './page-not-found.vue';
import RouteProcessor from '../utils/route-processor.js';

export default {
    name: 'use-cases',
    props: {
        caseId: String,
    },
    components: {
        ItemDescription,
        MdsButton,
        MdsCard,
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        MediaCarousel,
        PageNotFound,
    },
    data() {
        return {
            HOME_URL,
            itemsData: []
        };
    },
    computed: {
        useCaseDetails() {
            return this.itemsData && this.itemsData.items?.find(item => RouteProcessor.getAlias(item.name) === this.caseId);
        },
    },
    methods: {
        async fetchItemsData() {
            const jsonFileResponse = await axios.get(USE_CASES_FILE);
            this.itemsData = jsonFileResponse.data;
        },
        getImageUrl(imgUrl) {
            return `/content/hidden-from-navigation/${imgUrl}`;
        },
        getUrlPath(caseStudyName) {
            return `${HOME_URL}/use-cases/${RouteProcessor.getAlias(caseStudyName)}`;
        }
    },
    async created() {
        await this.fetchItemsData();
    },
    watch: {
        $route: function() {
            this.fetchItemsData();
        },
    },
}
</script>
<style lang="scss" module>
@import '@mds/typography';

.use-cases {
    padding: $mds-space-4-x $mds-space-4-x 0;
    margin: auto;
    max-width: 1347px;

    &__title {
        @include mds-level-1-heading();

        color: $mds-text-color-primary;
        margin: 0 0 $mds-space-3-x;
    }

    &__overview > p {
        @include mds-body-text-l();

        a.mds-button {
            vertical-align: bottom;
        }
    }

    &__back-btn {
        margin-bottom: $mds-space-8-x;
    }

    &__row {
        &--with-logo {
            flex-direction: column-reverse;

            @media screen and (min-width: 768px) {
                flex-direction: row;
            }
        }

        &--containing-cards {
            padding-top: $mds-space-3-x;
            padding-bottom: $mds-space-3-x;
        }

        &--with-spacer {
            padding-bottom: $mds-space-1-x * 12;
        }
    }

    .mds-card {
        margin: $mds-space-2-x 0;
        display: flex;
        flex-direction: column;
        position: relative;
        padding-bottom: 45px; /* space for absolutely positioned button (29px button height + 16px card padding) */
        height: calc(100% - $mds-space-4-x); /* 100% - (margin-top + margin-bottom) */

        &__title {
            @include mds-level-5-heading($bold: true);
        }

        &__supplemental-content {
            align-items: center;
            display: flex;
            justify-content: center;
            height: 190px;
            padding: $mds-space-2-x $mds-space-2-x 0;

            img {
                max-height: 100%;
            }
        }

        &__action {
            position: absolute;
            bottom: $mds-space-2-x; /* Card padding */
        }
    }

    .carousel-container {
        background-color: $mds-background-color-light-gray;
    }

    &__footer {
        text-align: center;
        color: $mds-text-color-primary-on-dark;
        padding: $mds-space-2-x;

        &-row {
            background-color: $mds-interactive-color-secondary-active;
        }

        & &-title {
            @include mds-level-1-heading();
            color: $mds-text-color-primary-on-dark;
            margin: $mds-space-1-x;
        }

        & &-text {
            @include mds-level-4-heading();
            color: $mds-text-color-primary-on-dark;

            &:before {
              content: none;
            }
        }

        .mds-button {
            margin: $mds-space-3-x 0 $mds-space-1-x;
        }

        .btn-link {
            margin: 0 auto;
        }
    }
}
</style>
