<template>
    <div class="footer-container___devsite">
      &copy;{{ new Date().getFullYear() }} <a href="https://www.morningstar.com/company" target="_blank">Morningstar, Inc.</a> All rights reserved.
      <a href="https://www.morningstar.com/company/privacy-policy" target="_blank">Terms of Use</a>
    </div>
</template>
<script>
export default {
  name: 'site-footer'
}
</script>
<style lang="scss">
@import '../style/components/site-footer';
</style>
