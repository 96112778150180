<template>
  <div
    class="new-landing-page mdc-page-shell mds-page-shell marketing-layout"
    data-v-46f13eb8
  >
    <div class="mds-page-shell__header" data-v-46f13eb8>
      <div class="mds-page-shell__header-inner" data-v-46f13eb8>
        <header
          data-v-3a988dc3
          data-v-09c240ee
          class="mdc-masthead mds-masthead marketing-header__masthead"
        >
          <div class="mdc-container" data-v-1e97672e data-v-3a988dc3>
            <div
              data-v-1e97672e
              data-v-3a988dc3
              class="mdc-masthead__inner mds-masthead__inner mds-masthead__inner__finder-flow"
            >
              <div
                data-v-1e97672e
                data-v-3a988dc3
                class="mds-masthead__logo-wrap mdc-masthead__logo-wrap masthead-logo"
              >
                <a
                  data-v-92ca4d76
                  data-v-09c240ee
                  href=""
                  tabindex="0"
                  class="mdc-link mdc-link--active"
                >
                  <img
                    data-v-92ca4d76
                    data-v-09c240ee
                    alt="Morningstar Logo"
                    src="/logo.svg"
                    class="mds-masthead__logo--wide"
                  />
                  <img
                    data-v-92ca4d76
                    data-v-09c240ee
                    alt="Morningstar Logo"
                    src="/logo.svg"
                    class="mds-masthead__logo"
                  />
                </a>
              </div>
              <div
                data-v-1e97672e
                data-v-3a988dc3
                class="mds-masthead__right masthead-controls"
              >
                <div class="new-landing-page__masthead-menu-container">
                  <mds-button
                    id="new-landing-page-masthead-button"
                    variation="icon-only"
                    :icon="toggleMenu ? 'remove' : 'menu'"
                    type="button"
                    @click="toggleMenu = !toggleMenu"
                  ></mds-button>
                </div>
                <div
                  data-v-1e97672e
                  data-v-09c240ee
                  class="new-landing-page__masthead-button-container"
                >
                  <a
                    data-v-92ca4d76
                    data-v-79b3565c
                    data-v-09c240ee
                    href="https://www.morningstar.com/views/contact-us"
                    tabindex="0"
                    target="_blank"
                    class="mdc-link mdc-button mds-button mdc-button--red"
                    type="button"
                    role="link"
                    size="medium"
                    data-v-1e97672e
                  >
                    <span
                      data-v-79b3565c=""
                      data-v-92ca4d76=""
                      class="mdc-button__content"
                    >
                      Contact Us
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="toggleMenu"
            data-v-3a988dc3
            class="mdc-masthead__inner mds-masthead__inner"
          >
            <div data-v-3a988dc3 class="masthead-nav">
              <div data-v-828ad248 data-v-09c240ee data-v-3a988dc3>
                <div
                  data-v-828ad248
                  class="marketing-mobile-nav"
                  :class="{ 'marketing-mobile-nav--open': toggleMenu }"
                >
                  <div data-v-828ad248 class="marketing-mobile-nav__cta-area">
                    <a
                      data-v-92ca4d76
                      data-v-79b3565c
                      data-v-828ad248
                      href="https://www.morningstar.com/views/contact-us"
                      tabindex="0"
                      target="_blank"
                      class="mdc-link mdc-button mds-button mdc-button--red mdc_showinmobile"
                      type="button"
                      role="link"
                      size="medium"
                    >
                      <span
                        data-v-79b3565c
                        data-v-92ca4d76
                        class="mdc-button__content"
                      >
                        Contact Us
                      </span>
                    </a>
                  </div>
                </div>
                <div
                  data-v-828ad248
                  class="marketing-mobile-nav__overlay"
                  :class="{
                    'marketing-mobile-nav__overlay--open': toggleMenu,
                  }"
                ></div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </div>

    <div class="mdc-page-shell__content" data-v-46f13eb8>
      <a id="start-of-content" tabindex="-1" data-v-46f13eb8></a>
      <main data-v-22377a69>
        <section
          data-v-022cf2c6
          data-v-22377a69
          class="mdc-marketing-section-basic mdc-marketing-section-basic--horizontal-aligment-left mdc-marketing-section-basic--vertical-spacing-xs mdc-marketing-section-basic--max-width-xl segmented-section_active"
          style="background: transparent"
        >
          <div data-v-022cf2c6 class="mdc-marketing-section-basic__container">
            <div class="mdc-marketing-section-basic__body" data-v-022cf2c6>
              <div
                class="mdc-marketing-section-basic__base-content"
                data-v-022cf2c6
              >
                <div
                  class="mdc-marketing-section-basic__heading"
                  data-v-022cf2c6
                >
                  <div class="mdc-rich-text" data-v-022cf2c6>
                    <p
                      data-v-022cf2c6
                      class="mdc-body-text mdc-heading--hero mdc-color-text--neutral-12"
                    >
                      <span data-v-022cf2c6
                        >Seamless Access to Morningstar Data and Research</span
                      >
                    </p>
                  </div>
                </div>
                <div
                  data-v-022cf2c6
                  class="mdc-marketing-section-basic__body-text"
                >
                  <div class="mdc-rich-text" data-v-022cf2c6>
                    <p
                      class="mdc-body-text mdc-heading--level-3 mdc-color-text--neutral-12"
                      data-v-022cf2c6
                    >
                      <span data-v-022cf2c6
                        >Access the data and research you need, when you need it, in the format that works for you.</span
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          data-v-022cf2c6
          data-v-22377a69
          class="mdc-marketing-section-basic mdc-marketing-section-basic--horizontal-aligment-center mdc-marketing-section-basic--vertical-spacing-xs mdc-marketing-section-basic--max-width-xl segmented-section_active"
        >
          <div class="mdc-marketing-section-basic__container" data-v-022cf2c6>
            <div class="mdc-marketing-section-basic__body" data-v-022cf2c6>
              <div
                class="mdc-marketing-section-basic__secondary-content"
                data-v-022cf2c6
              >
                <div
                  class="mdc-grid mds-layout-grid"
                  data-v-bcc0c73a
                  data-v-022cf2c6
                >
                  <div
                    class="mdc-row mds-layout-grid__row mds-layout-grid__row--justify-content-center mds-layout-grid__row--align-items-flex-start"
                    data-v-18387644
                    data-v-022cf2c6
                  >
                    <div
                      class="mdc-column mds-layout-grid__col mds-layout-grid__col--12 mdc-column--order-default mdc-column--order-default-at-600 mdc-column--order-default-at-768 mdc-column--order-default-at-1092 mdc-column--order-default-at-1304 mds-layout-grid__col--4-at-768"
                      data-v-0ef6d8d7
                      data-v-022cf2c6
                    >
                      <div
                        class="mdc-marketing-basic-column mdc-marketing-basic-column--horizontally-left-aligned mdc-marketing-basic-column--vertically-top-aligned mdc-marketing-basic-column--vertical-layout mdc-marketing-basic-column--v1"
                        data-v-bfa57914
                        data-v-022cf2c6
                      >
                        <div
                          class="mdc-marketing-basic-column__main"
                          style="padding-left: 0px; padding-right: 0px"
                          data-v-bfa57914
                        >
                          <div
                            class="mdc-marketing-basic-column__heading"
                            data-v-bfa57914
                          >
                            <div class="mdc-rich-text" data-v-bfa57914>
                              <p
                                class="mdc-body-text mdc-heading--marketing-eyebrow mdc-color-text--black"
                                data-v-bfa57914
                              >
                                <span data-v-bfa57914
                                  >Intelligence Engine API</span
                                >
                              </p>
                            </div>
                            <div class="mdc-rich-text" data-v-bfa57914>
                              <h1
                                class="mdc-heading mdc-heading--level-1 mdc-color-text--neutral-12"
                                data-v-bfa57914
                              >
                                Generative AI
                              </h1>
                            </div>
                          </div>
                          <div
                            class="mdc-marketing-basic-column__body"
                            data-v-bfa57914
                          >
                            <div class="mdc-rich-text" data-v-bfa57914>
                              <h4
                                class="mdc-heading mdc-heading--level-4 mdc-color-text--neutral-12"
                                data-v-bfa57914
                              >
                                <span data-v-bfa57914>
                                  Cutting-edge technology with your firm's unique perspective to help you deliver enterprise-scale investment services that are personal, effective and efficient.
                                </span>
                              </h4>
                            </div>
                          </div>
                          <div
                            data-v-6cb9293a
                            data-v-bfa57914
                            class="mdc-marketing-button-group mdc-marketing-basic-column__button-wrapper mdc-marketing-button-group--left"
                          >
                            <a
                              data-v-92ca4d76
                              data-v-79b3565c
                              data-v-6cb9293a
                              :href="HOME_URL + '/documentation/dynamic-services-apis/intelligence-engine/overview'"
                              tabindex="0"
                              target="_blank"
                              type="button"
                              role="link"
                              size="large"
                              class="mdc-link mdc-button mds-button mds-button--secondary mds-button--large"
                            >
                              <span
                                data-v-92ca4d76
                                data-v-79b3565c
                                class="mdc-button__content"
                              >
                                Learn More
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="mdc-column mds-layout-grid__col mds-layout-grid__col--12 mdc-column--order-default mdc-column--order-default-at-600 mdc-column--order-default-at-768 mdc-column--order-default-at-1092 mdc-column--order-default-at-1304 mds-layout-grid__col--4-at-768"
                      data-v-0ef6d8d7
                      data-v-022cf2c6
                    >
                      <div
                        class="mdc-marketing-basic-column mdc-marketing-basic-column--horizontally-left-aligned mdc-marketing-basic-column--vertically-top-aligned mdc-marketing-basic-column--vertical-layout mdc-marketing-basic-column--v1"
                        data-v-bfa57914
                        data-v-022cf2c6
                      >
                        <div
                          class="mdc-marketing-basic-column__main"
                          style="padding-left: 0px; padding-right: 0px"
                          data-v-bfa57914
                        >
                          <div
                            class="mdc-marketing-basic-column__heading"
                            data-v-bfa57914
                          >
                            <div class="mdc-rich-text" data-v-bfa57914>
                              <p
                                class="mdc-body-text mdc-heading--marketing-eyebrow mdc-color-text--black"
                                data-v-bfa57914
                              >
                                <span data-v-bfa57914
                                  >{{ TEAM_NAME }}</span
                                >
                              </p>
                            </div>
                            <div class="mdc-rich-text" data-v-bfa57914>
                              <h1
                                class="mdc-heading mdc-heading--level-1 mdc-color-text--neutral-12"
                                data-v-bfa57914
                              >
                                RESTful APIs
                              </h1>
                            </div>
                          </div>
                          <div
                            class="mdc-marketing-basic-column__body"
                            data-v-bfa57914
                          >
                            <div class="mdc-rich-text" data-v-bfa57914>
                              <h4
                                class="mdc-heading mdc-heading--level-4 mdc-color-text--neutral-12"
                                data-v-bfa57914
                              >
                                <span data-v-bfa57914>
                                  Seamlessly integrate Morningstar's data, research, and reports to create world-class digital experiences and power new insights with portfolio analytics and investment research capabilities.
                                </span>
                              </h4>
                            </div>
                          </div>
                          <div
                            data-v-6cb9293a
                            data-v-bfa57914
                            class="mdc-marketing-button-group mdc-marketing-basic-column__button-wrapper mdc-marketing-button-group--left"
                          >
                            <a
                              data-v-92ca4d76
                              data-v-79b3565c
                              data-v-6cb9293a
                              :href="HOME_URL"
                              tabindex="0"
                              target="_blank"
                              type="button"
                              role="link"
                              size="large"
                              class="mdc-link mdc-button mds-button mds-button--secondary mds-button--large"
                            >
                              <span
                                data-v-92ca4d76
                                data-v-79b3565c
                                class="mdc-button__content"
                              >
                                Learn More
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      data-v-0ef6d8d7
                      data-v-022cf2c6
                      class="mdc-column mds-layout-grid__col mds-layout-grid__col--12 mdc-column--order-default mdc-column--order-default-at-600 mdc-column--order-default-at-768 mdc-column--order-default-at-1092 mdc-column--order-default-at-1304 mds-layout-grid__col--4-at-768"
                    >
                      <div
                        data-v-bfa57914
                        data-v-022cf2c6
                        class="mdc-marketing-basic-column mdc-marketing-basic-column--horizontally-left-aligned mdc-marketing-basic-column--vertically-top-aligned mdc-marketing-basic-column--vertical-layout mdc-marketing-basic-column--v1"
                      >
                        <div
                          data-v-bfa57914
                          class="mdc-marketing-basic-column__main"
                          style="padding-left: 0px; padding-right: 0px"
                        >
                          <div
                            data-v-bfa57914
                            class="mdc-marketing-basic-column__heading"
                          >
                            <div class="mdc-rich-text" data-v-bfa57914>
                              <p
                                class="mdc-body-text mdc-heading--marketing-eyebrow mdc-color-text--neutral-12"
                                data-v-bfa57914
                              >
                                morningstar_data
                              </p>
                            </div>
                            <div class="mdc-rich-text" data-v-bfa57914>
                              <h1
                                class="mdc-heading mdc-heading--level-1 mdc-color-text--neutral-12"
                                data-v-bfa57914
                              >
                                <span data-v-bfa57914
                                  >Python Package</span
                                >
                              </h1>
                            </div>
                          </div>
                          <div
                            class="mdc-marketing-basic-column__body"
                            data-v-bfa57914
                          >
                            <div class="mdc-rich-text" data-v-bfa57914>
                              <h4
                                class="mdc-heading mdc-heading--level-4 mdc-color-text--neutral-12"
                                data-v-bfa57914
                              >
                                <span data-v-bfa57914
                                  >Unlock the potential of Morningstar Direct by directly accessing data. Investigate Morningstar data more deeply than ever and discover new opportunities for investing success.</span
                                >
                              </h4>
                            </div>
                          </div>
                          <div
                            data-v-6cb9293a
                            data-v-bfa57914
                            class="mdc-marketing-button-group mdc-marketing-basic-column__button-wrapper mdc-marketing-button-group--left"
                          >
                            <a
                              data-v-92ca4d76
                              data-v-79b3565c
                              data-v-6cb9293a
                              :href="
                                PYTHON_PACKAGE_PAGE_HOSTNAME +
                                '/products/md-python-package'
                              "
                              tabindex="0"
                              target="_blank"
                              type="button"
                              role="link"
                              size="large"
                              class="mdc-link mdc-button mds-button mds-button--secondary mds-button--large"
                            >
                              <span
                                data-v-92ca4d76
                                data-v-79b3565c
                                class="mdc-button__content"
                              >
                                Learn More
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="mdc-marketing-section-basic mdc-marketing-section-basic--horizontal-aligment-left mdc-marketing-section-basic--vertical-spacing-xs mdc-marketing-section-basic--max-width-m segmented-section_active"
          data-v-022cf2c6
          data-v-22377a69
        >
          <div class="mdc-marketing-section-basic__container" data-v-022cf2c6>
            <div class="mdc-marketing-section-basic__body" data-v-022cf2c6>
              <div
                class="mdc-marketing-section-basic__secondary-content"
                data-v-022cf2c6
              >
                <div
                  class="mdc-grid mds-layout-grid"
                  data-v-bcc0c73a
                  data-v-022cf2c6
                >
                  <div
                    class="mdc-row mds-layout-grid__row mds-layout-grid__row--align-items-center"
                    data-v-18387644
                    data-v-022cf2c6
                  >
                    <div
                      class="mdc-column mds-layout-grid__col mds-layout-grid__col--12 mdc-column--order-default mdc-column--order-default-at-600 mdc-column--order-default-at-768 mdc-column--order-default-at-1092 mdc-column--order-default-at-1304"
                      data-v-0ef6d8d7
                      data-v-022cf2c6
                    >
                      <div
                        class="mdc-quote-card"
                        data-v-9ad5044c
                        data-v-022cf2c6
                      >
                        <h4
                          class="mdc-heading mdc-block-quote mdc-heading--level-1 mdc-color-text--neutral-12"
                          data-v-3c7fda18
                          data-v-9ad5044c
                        >
                          <svg
                            data-v-3c7fda18
                            class="quote-icon quote-icon-size-1"
                            viewBox="0 0 64 50"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <title>quote_start</title>
                            <path
                              d="M27.3,3 L27.3,48.5 L1.5,48.5 L1.5,22.2 L27.3,3 M28.8,0.1 L0,21.4 L0,50 L28.8,50 L28.8,0.1 Z"
                              id="nXMLID_8417_"
                            ></path>
                            <path
                              d="M60.8,2.9 L60.8,48.4 L35,48.4 L35,22.1 L60.8,2.9 M62.3,0 L33.5,21.4 L33.5,50 L62.3,50 L62.3,0 L62.3,0 Z"
                              id="nXMLID_8416_"
                            ></path>
                          </svg>
                          Analytics Lab and the morningstar_data Python package
                          give our users more flexibility than ever to explore
                          the cutting edge of data science.
                          <svg
                            data-v-3c7fda18
                            class="quote-icon quote-icon-size-1"
                            viewBox="0 0 64 50"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <title>quote_end</title>
                            <path
                              d="M27.3,1.5 L27.3,47 L1.5,27.8 L1.5,1.5 L27.3,1.5 M28.8,0 L1.42108547e-14,0 L1.42108547e-14,28.6 L28.8,50 L28.8,0 Z"
                              id="aXMLID_8417_"
                            ></path>
                            <path
                              d="M60.7,1.5 L60.7,47 L34.9,27.8 L34.9,1.5 L60.7,1.5 M62.2,0 L33.4,0 L33.4,28.6 L62.2,50 L62.2,0 Z"
                              id="aXMLID_8416_"
                            ></path>
                          </svg>
                        </h4>
                        <div
                          class="mdc-grid mds-layout-grid"
                          data-v-bcc0c73a
                          data-v-9ad5044c
                        >
                          <div
                            class="mdc-row mds-layout-grid__row author-block"
                            data-v-18387644
                            data-v-9ad5044c
                          >
                            <div
                              class="mdc-column mds-layout-grid__col mds-layout-grid__col--12-at-600 mds-layout-grid__col--9-at-1092 mdc-color-text--neutral-12"
                              data-v-0ef6d8d7
                              data-v-9ad5044c
                            >
                              <h5
                                class="mdc-heading mdc-heading--level-5"
                                data-v-9ad5044c
                              >
                                James Rhodes, Morningstar CTO and President of
                                Data, Research, and Enterprise Solutions
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
    <footer
      class="mdc-page-shell__footer mdc-page-shell__footer--fixed-width"
      data-v-46f13eb8
    >
      <div class="mdc-page-shell__footer-container" data-v-46f13eb8>
        <site-footer></site-footer>
      </div>
    </footer>
  </div>
</template>
<script>
import { MdsButton } from "@mds/button";
import { HOME_URL, PYTHON_PACKAGE_PAGE_HOSTNAME, TEAM_NAME } from "../data/constants";
import SiteFooter from "./site-footer.vue";

export default {
  name: "new-landing-page",
  components: {
    MdsButton,
    SiteFooter,
  },
  data() {
    return {
      HOME_URL,
      PYTHON_PACKAGE_PAGE_HOSTNAME,
      TEAM_NAME,
      toggleMenu: false,
    };
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.toggleMenu = false;
    },
  },
};
</script>
<style lang="scss">
@import "@mds/constants";

.new-landing-page {
  &__masthead-menu-container {
    display: block;
  }
  &__masthead-button-container {
    display: none;
  }

  @media only screen and (min-width: $mds-breakpoint-m) {
    &__masthead-menu-container {
      display: none;
    }
    &__masthead-button-container {
      display: flex;
    }
  }

  .mdc-heading--hero {
    font-size: 69px;
  }

  main .mdc-marketing-section-basic {
    &:nth-child(2) {
      background: linear-gradient(
        180deg,
        rgba(242, 242, 242, 0.5) 0%,
        $mds-color-white 100%
      );
    }
    &:nth-child(3) {
      background: rgba(242, 242, 242, 0.5);
    }
  }

  .mdc-marketing-basic-column {
    background-color: $mds-color-neutral-95;
    background-position: center top;
    padding: $mds-space-3-x;

    @media only screen and (min-width: $mds-breakpoint-m) {
      height: 745px;
    }

    @media only screen and (min-width: 1092px) {
      height: 420px;
    }

    @media only screen and (min-width: 1304px) {
      height: 365px;
    }
  }
}
</style>
