import axios from 'axios';
import { CONTENT_TYPE } from '@/data/constants';
import { NAVIGATION_FILE } from '../data/constants';
import RouteProcessor from '../utils/route-processor';

const sortingExceptions = ['OVERVIEW', 'MORNINGSTAR APIS', 'MORNINGSTAR COMPONENTS', 'GET STARTED'];

const sortSectionItems = (navData, type) => {
    if (!Array.isArray(navData)) return [];
    return navData.map(section => {
        section.items.sort((itemA, itemB) => {
            const nameA = itemA.text.toUpperCase();
            const nameB = itemB.text.toUpperCase();
            if (sortingExceptions.indexOf(nameA) > -1) return -1;
            if (sortingExceptions.indexOf(nameB) > -1) return 1;
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        })
        return section;
    }).sort((a, b) => {
        if (RouteProcessor.getAlias(a.section) === type) return -1;
        if (RouteProcessor.getAlias(b.section) === type) return 1;
        return 0;
    });
}

// This class is a lazy-initialized singleton intended to fetch and hold
// the dynamic nav data for all content types
export class NavDataService {

     initialized = {}
     navData = {}

    // Initialize the constant data
    constructor() {
      for(const typeValue of Object.values(CONTENT_TYPE)) {
          this.initialized[typeValue] = false;
          this.navData[typeValue] = [];
      }
    }

    async GetNavData(type) {

        if(!(Object.values(CONTENT_TYPE).includes(type))
            || [CONTENT_TYPE.PARTNERS, CONTENT_TYPE.USE_CASES].indexOf(type) > -1) {
            return [];
        }

        if(this.initialized[type]) {
            return this.navData[type];
        }

        // Note there is a race condition here - while one thread awaits the get, another may reach
        // this point as well.
        const navigation = await axios.get(NAVIGATION_FILE[type]);
        this.navData[type] = sortSectionItems(navigation.data, type);
        this.initialized[type] = true;

        return this.navData[type];
    }
}

// Exporting an instance rather than the class definition makes this a singleton
export default new NavDataService();