<template>
  <div class="sign-up-basic">
    <div class="sign-up-basic__banner">
      <mds-banner
        v-if="showSignUpFailed"
        variation="error"
        :persistent="true">
        <div class="sign-up-basic__banner-header">Sign-up attempt was unsuccessful.</div>
        <div>We encountered an issue when trying to register your details. Please try again.</div>
      </mds-banner>
      <mds-banner
        v-if="showPasswordMailInfo"
        v-on:mds-banner-dismissed="userCreatedBannerDismissed">
        <div class="sign-up-basic__banner-header">You have signed up to Morningstar Developer.</div>
        <div>You will receive an email with a link to set up your password. Follow the instructions and then sign in using your email address and the password you created.</div>
      </mds-banner>
    </div>
    <main>
      <h2 class="sign-up-basic__header">Sign Up to Morningstar Developer</h2>
      <section class="sign-up-basic__sign-in">
        <div>
          Signing up allows you to preview and interact with our APIs and components.
        </div>
        <div>
          Already signed up or have an existing Morningstar Account?
          <mds-button
            class="sign-up-basic__sign-in-button"
            text="Sign in."
            variation="flat"
            @click="signInUser"></mds-button>
        </div>
      </section>
      <mds-form size="large">
          <mds-required-field-key
              size="small"
              text="Required fields"
          ></mds-required-field-key>
          <mds-fieldset
              hidden-legend
              legend="Account"
              class="sign-up-basic__account-fieldset"
          >
            <div class="sign-up-basic__fieldset-horizontal-div">
              <mds-input
                  v-model="firstName"
                  :disabled="showPasswordMailInfo"
                  :error="isInvalid.firstName"
                  :error-text="[errorMessages.firstName]"
                  :required-text="errorMessages.firstName"
                  label="First Name"
                  required
                  @input.native="verify('firstName')"
              ></mds-input>
              <mds-input
                  v-model="lastName"
                  :disabled="showPasswordMailInfo"
                  :error="isInvalid.lastName"
                  :error-text="[errorMessages.lastName]"
                  :required-text="errorMessages.lastName"
                  label="Last Name"
                  required
                  @input.native="verify('lastName')"
              ></mds-input>
            </div>
            <div class="sign-up-basic__fieldset-horizontal-div">
              <mds-input
                  v-model="emailId"
                  :disabled="showPasswordMailInfo"
                  :error="isInvalid.emailId"
                  :error-text="[errorMessages.emailId]"
                  :required-text="errorMessages.emailId"
                  label="Email Address"
                  required
                  type="email"
                  @input.native="verify('emailId')"
              ></mds-input>
              <mds-input
                  v-model="mobilePhoneNumber"
                  :disabled="showPasswordMailInfo"
                  :error="isInvalid.mobilePhoneNumber"
                  :error-text="[errorMessages.mobilePhoneNumber]"
                  :required-text="errorMessages.mobilePhoneNumber"
                  required
                  label="Mobile Phone Number"
              ></mds-input>
            </div>
          </mds-fieldset>
          <mds-fieldset
              hidden-legend
              legend="Contact details"
              class="sign-up-basic__contact-details-fieldset"
          >
              <mds-input
                  v-model="addressLine1"
                  :disabled="showPasswordMailInfo"
                  :error="isInvalid.addressLine1"
                  :error-text="[errorMessages.addressLine1]"
                  :required-text="errorMessages.addressLine1"
                  required
                  label="Address Line 1"
              ></mds-input>
              <mds-input
                  v-model="addressLine2"
                  :disabled="showPasswordMailInfo"
                  :error="isInvalid.addressLine2"
                  :error-text="[errorMessages.addressLine2]"
                  :required-text="errorMessages.addressLine2"
                  required
                  label="Address Line 2"
              ></mds-input>
            <div class="sign-up-basic__fieldset-horizontal-div">
              <mds-input
                  v-model="city"
                  :disabled="showPasswordMailInfo"
                  :error="isInvalid.city"
                  :error-text="[errorMessages.city]"
                  :required-text="errorMessages.city"
                  required
                  label="City"
              ></mds-input>
              <mds-input
                  v-model="state"
                  :disabled="showPasswordMailInfo"
                  :error="isInvalid.state"
                  :error-text="[errorMessages.state]"
                  :required-text="errorMessages.state"
                  required
                  label="State"
              ></mds-input>
            </div>
            <div class="sign-up-basic__fieldset-horizontal-div">
              <mds-input
                  v-model="country"
                  :disabled="showPasswordMailInfo"
                  :error="isInvalid.country"
                  :error-text="[errorMessages.country]"
                  :required-text="errorMessages.country"
                  required
                  label="Country"
              ></mds-input>
              <mds-input
                  v-model="zip"
                  :disabled="showPasswordMailInfo"
                  :error="isInvalid.zip"
                  :error-text="[errorMessages.zip]"
                  :required-text="errorMessages.zip"
                  required
                  label="Zip"
              ></mds-input>
            </div>
          </mds-fieldset>
          <mds-button-container :rightAligned="true">
            <mds-button
              size="large"
              variation="primary"
              @click.prevent="createAccount"
              :loading="isLoading"
              :disabled="showPasswordMailInfo"
            >
              Create Account
            </mds-button>
          </mds-button-container>
      </mds-form>
    </main>
  </div>
</template>

<script>
import MdsBanner from '@mds/banner';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsFieldset from '@mds/fieldset';
import MdsForm from '@mds/form';
import MdsInput from '@mds/input';
import MdsRequiredFieldKey from '@mds/required-field-key';
import UserRegistrationService from '../utils/user-registration-service';

export default {
  metaInfo: {
    title: "Sign-up",
  },
  requiredFields: ['firstName', 'lastName', 'emailId', 'addressLine1', 'addressLine2', 'city', 'state', 'country', 'zip', 'mobilePhoneNumber'],
  components: {
    MdsBanner,
    MdsButton,
    MdsButtonContainer,
    MdsFieldset,
    MdsForm,
    MdsInput,
    MdsRequiredFieldKey,
  },
  data() {
    const requiredFieldsInvalidState = this.$options.requiredFields.reduce(
      (result, fieldName) => {
        result[fieldName] = false;
        return result;
      },
      {},
    );
    return {
      firstName: '',
      lastName: '',
      emailId: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      country: '',
      zip: '',
      mobilePhoneNumber: '',
      errorMessages: {
        firstName: 'First name is required',
        lastName: 'Last name is required',
        emailId: '',
        addressLine1: 'Address line 1 is required',
        addressLine2: 'Address line 2 is required',
        city: 'City is required',
        state: 'State is required',
        country: 'Country is required',
        zip: 'Zip is required',
        mobilePhoneNumber: 'Mobile phone number is required',
      },
      isInvalid: requiredFieldsInvalidState,
      isLoading: false,
      showPasswordMailInfo: false,
      showSignUpFailed: false,
      verifyFields: false,
    };
  },
  created() {
      UserRegistrationService.Subscribe(UserRegistrationService.EVENT_TYPE.USER_REGISTERED_SUCCESSFULLY, this.userSignedUp);
      UserRegistrationService.Subscribe(UserRegistrationService.EVENT_TYPE.USER_REGISTRATION_FAILED, this.userSignUpFailed);
  },
  methods: {
    verify(paramName) {
      if (this.verifyFields) {
        let isValid = false;
        switch(paramName) {
          case 'emailId':
            isValid = this.verifyEmailId(this[paramName]);
            break;
          default:
            isValid = this[paramName];
            break;
        }
        this.isInvalid[paramName] = !isValid;
        // this.$set(this.isInvalid, paramName, !this[paramName]);
        // this.isInvalid = Object.assign({}, this.isInvalid, { [paramName]: !this[paramName] });
      }
    },
    verifyEmailId(emailId) {
      let errorMessage;
      if (!emailId) {
        errorMessage = 'Email address is required';
      } else if (!/\S+@\S+.\S+\S+/.test(emailId)) {
        errorMessage = 'Email address is not in correct format';
      }
      this.errorMessages.emailId = errorMessage;
      return !errorMessage;
    },
    createAccount() {
      this.showSignUpFailed = false;
      this.verifyFields = true;
      this.$options.requiredFields.forEach((field) => {
        this.verify(field);
      });
      if (!this.isLoading && Object.keys(this.isInvalid).every(field => this.isInvalid[field] === false)) {
        this.isLoading = true;
        UserRegistrationService.RegisterUser({
          email: this.emailId,
          firstName: this.firstName,
          lastName: this.lastName,
          address1: this.addressLine1,
          address2: this.addressLine2,
          city: this.city,
          state: this.state,
          zip: this.zip,
          mobilePhone: this.mobilePhoneNumber,
          country: this.country,
        });
      }
    },
    signInUser() {
      this.$emit('signIn');
    },
    userSignedUp() {
      this.isLoading = false;
      this.showPasswordMailInfo = true;
      this.showSignUpFailed = false;
    },
    userSignUpFailed() {
      this.isLoading = false;
      this.showSignUpFailed = true;
    },
    userCreatedBannerDismissed() {
      this.showPasswordMailInfo = false;
    },
  },
};
</script>

<style lang="scss">
@import "../style/components/sign-up";
</style>
