<script>
import MarkdownIt from 'markdown-it';
import MarkdownItAttrs from 'markdown-it-attrs';

export default {
    name: 'item-description',
    props: {
        description: Array,
    },
    md: new MarkdownIt({
        html: true,
        linkify: true,
        breaks: true,
        typographer: true,
    }).use(MarkdownItAttrs),
    render(createElement) {
        const renderedHtml = this.description ? this.$options.md.render(this.description.join('\n')) : '';

        return createElement('div', {
            class: 'item-description',
            domProps: {
                innerHTML: renderedHtml,
            },
        });
    },
    mounted() {
        this.$emit('component-rendered');
    },
}
</script>