export const PAGE_TYPE = {
  IFRAME: 'IFRAME',
  SWAGGER: 'SWAGGER',
  MARKDOWN: 'MARKDOWN'
};

export const HOME_URL_V0 = '/dynamic-services-apis';
export const HOME_URL = '/direct-web-services';

export const PYTHON_PACKAGE_PAGE_HOSTNAME = process.env.VUE_APP_PYTHON_PACKAGE_PAGE_HOSTNAME;

export const TEAM_NAME = "Direct Web Services";

export const CONTENT_TYPE = {
  APIS: 'apis',
  COMPONENTS: 'components',
  API_VISUALIZATION_LIBRARY: 'api-visualization-library',
  SOLUTIONS: 'solutions',
  DOCUMENTATION: 'documentation',
  PARTNERS: 'partners',
  USE_CASES: 'use-cases',
};

export const NAVIGATION_FILE = {
  [CONTENT_TYPE.APIS]: process.env.VUE_APP_API_NAVIGATION_FILE,
  [CONTENT_TYPE.API_VISUALIZATION_LIBRARY]: process.env.VUE_APP_API_VISUALIZATION_LIBRARY_NAVIGATION_FILE,
  [CONTENT_TYPE.DOCUMENTATION]: process.env.VUE_APP_DOCUMENTATION_NAVIGATION_FILE,
  [CONTENT_TYPE.COMPONENTS]: process.env.VUE_APP_COMPONENT_NAVIGATION_FILE,
};

export const PARTNERS_FILE = `${process.env.VUE_APP_SHARED_HIDDEN_FOLDER_URL}/partners-data.json`;
export const USE_CASES_FILE = `${process.env.VUE_APP_SHARED_HIDDEN_FOLDER_URL}/case-studies-data.json`;

export const SOLUTIONS_PAGE_URL = 'https://www.morningstar.com/products/dynamic-services-apis';

export const SECTION_ITEM_TYPE = {
  SINGLE_LINK: 'SINGLE_LINK',
  SUBLIST: 'SUBLIST',
  VERSIONED_SINGLE_LINK: 'VERSIONED_SINGLE_LINK',
  VERSIONED_SUBLIST: 'VERSIONED_SUBLIST'
};

export const REGION = {
  ALL: 'all',
  EMEA: 'emea',
  NA: 'na',
};

export const ENABLE_USER_MANAGEMENT = false;
