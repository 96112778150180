<template>
  <div class="page-404-container">
    <mds-empty-state
      title="The page you were looking for does not exist."
      :message="message"
      size="large"
    >
      <template v-slot:mds-empty-state-icon>
        <img
          src="../images/404-face.svg"
          role="presentation"
        >
    </template>
    </mds-empty-state>
  </div>
</template>

<script>
import MdsEmptyState from '@mds/empty-state';

export default {
  name: 'page-not-found',
  components: {
    MdsEmptyState
  },
  props: {
    message: {
      type: String,
      default: "Check your URL, or use the site navigation to locate your content.",
    },
  },
}
</script>

<style lang="scss">
@import '../style/components/page-not-found';
</style>