<script>
import markdownItPrism from '../scripts/components/doc-md_prism.js';
import MarkdownIt from 'markdown-it';
import markdownItAttrs from 'markdown-it-attrs';
import markdownItAnchor from 'markdown-it-anchor';
import markdownItCollapsible from 'markdown-it-collapsible';
import markdownItMultimdTable from 'markdown-it-multimd-table';
import MdsSwitch from '@mds/switch';
import Vue from 'vue';
import { name, version } from '../../package.json';
import axios from 'axios';

export default {
  name: 'MdsDocMd',
  props: {
    options: {
      type: Object,
      default() {
        return {
          markdownIt: {
            linkify: true,
            breaks: true,
            typographer: true,
          },
        };
      },
      docs: {
        validation: '—',
        description: 'Markdown parser options.',
      },
    },
    disableClass: {
      type: Boolean,
      default: false,
      docs: {
        validation: '—',
        description: 'If `true`, do not automatically add doc-typography class name.',
      },
    },
    url: {
      type: String,
      default: '',
    },
    version: {
      type: String,
      default: `${name}-${version}`,
    },
  },
  md: new MarkdownIt(),
  template: `
            <div>
                <slot></slot>
            </div>
        `,
  data() {
    return {
      sourceData: '',
      renderedHtml: '',
      sectionTitles: [],
    };
  },
  async created() {
    this.sourceData = await this.getUrlData(this.url);
  },
  methods: {
    async getUrlData(url) {
      return (await axios.get(url)).data;
    },
    addCodeBlockSwitch() {
      const docElement = this.$el;
      if (docElement) {
        const preLists = docElement.querySelectorAll('pre');

        if (preLists.length > 0) {
          preLists.forEach((elem) => {
            const Component = Vue.extend(MdsSwitch);
            const instance = new Component({
              propsData: {
                label: 'Dark Mode',
                size: 'small',
              }
            });
            instance.$on('change', (event) => {
              const inputs = docElement.querySelectorAll('.md-docs-dark-mode___devsite input');
              preLists.forEach((elems) => elems.classList.toggle('pre-on-dark___devsite'));
              inputs.forEach((elem) => {
                elem.checked = event;
              });
            })
            instance.$mount();
            instance.$el.classList.add('class', 'md-docs-dark-mode___devsite');
            elem.before(instance.$el);
          });
        }
      }
    },
    setActiveTab() {
      const docElement = this.$el;
      if (docElement) {
        const tabLists = docElement.querySelectorAll('nav.md-docs-tabs-action___devsite ul li');
        const activeClass = 'md-docs-tabs-action--active___devsite';
        const hashUrlParams = this.$router.history.current.hash;

        if (tabLists.length > 0) {
          if (!hashUrlParams) {
            tabLists[0].classList.add(activeClass);
          }
          tabLists.forEach((tab) => {
            const link = tab.querySelector('a').getAttribute('href');
            if (hashUrlParams && hashUrlParams === link) {
              tab.classList.add(activeClass);
            }
            tab.addEventListener('click', () => {
              tabLists.forEach((elem) => elem.classList.remove(activeClass));
              tab.classList.add(activeClass);
            });
          });
        }
      }
    },
    setHeaders() {
      let headerId = '';
      let title = '';
      const titles = [];

      const proxy = (tokens, idx, options, env, self) => self.renderToken(tokens, idx, options);
      const defaultHeadingOpenRenderer = this.md.renderer.rules.heading_open || proxy;
      const defaultHeadingCloseRenderer = this.md.renderer.rules.heading_close || proxy;

      this.md.renderer.rules.heading_open = (...args) => {
        const [tokens, idx] = args;
        if (tokens[idx].attrs) {
          headerId = tokens[idx].attrs
            .filter((attribute) => attribute.some((item) => item === 'id'))
            .map((attribute) => attribute[1])
            .join('');

          title = tokens[idx + 1].children
            .filter((t) => ['text'].includes(t.type))
            .reduce((acc, t) => acc + t.content, '');
        }

        return defaultHeadingOpenRenderer(...args);
      };

      this.md.renderer.rules.heading_close = (...args) => {
        let anchor = '';
        const [tokens, idx] = args;

        if (tokens[idx - 2].attrs) {
          anchor = `<a class="mds-doc-anchor-link___devsite" href="#${headerId}" aria-labelledby="${headerId}"></a>`;

          if (tokens[idx - 2].tag === 'h3') {
            titles.push({
              name: title,
              href: `#${headerId}`,
            });
          }
        }
        return `${anchor}${defaultHeadingCloseRenderer(...args)}`;
      };

      this.renderedHtml = this.md.render(this.sourceData);
      this.setActiveTab(); // convert lists to tabs along with sticky header
      this.addCodeBlockSwitch(); // to add switch for dark mode before code block. 
      this.sectionTitles = titles;
    },
  },
  render(createElement) {
    // slugify any special characters
    const uslug = require('uslug');
    const uslugify = (s) => uslug(s);

    this.md = new MarkdownIt({
        html: true,
      })
      .use(markdownItAttrs)
      .use(markdownItAnchor, {
        level: 2, // start from h2
        slugify: uslugify,
      })
      .use(markdownItCollapsible)
      .use(markdownItMultimdTable, {
        multiline:  true,
        rowspan:    true,
        headerless: true,
      })
      .use(markdownItPrism, {
        styles: this.$style,
      });
    this.md.set(this.options.markdownIt);

    // Overwrite markdown-it rules
    this.setHeaders();

    const classes = this.disableClass
      ? this.$style['mds-doc-md']
      : [this.$style['mds-doc-md'], this.$style['mds-doc-typography']];

    return createElement('div', {
      domProps: {
        innerHTML: this.renderedHtml,
      },
      class: classes,
      attrs: {
        'data-mds-version': this.version,
      },
    });
  },
  beforeMount() {
    if (this.$slots.default) {
      this.sourceData = '';
      for (const slot of this.$slots.default) {
        this.sourceData += slot.text;
      }
    }
  },
  mounted() {
    if (this.$store && this.sectionTitles.length > 0) {
      this.$store.commit('addTitles', this.sectionTitles);
    }
  },
  destroyed() {
    if (this.$store && this.$store.getters.getTitles.length > 0) {
      this.$store.commit('resetTitles');
    }
  },
  watch: {
    async url(val) {
      this.sourceData = await this.getUrlData(val);
    },
  },
};
</script>

<style lang="scss" module>
@import '../style/components/doc-md';
</style>