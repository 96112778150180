// This is intended to be shared code to handle route transformations between the path string,
// the route params, and / or the indexes in the navData
export default class RouteProcessor {

    // Initialize the constant data
    constructor() {
    }

    static getAlias(value) {
      if (value) {
        return value.toLowerCase().replace(/[^\w\s\-.]+/g,'').replace(/^\s+|\s+$/gm,'').replace(/\s+/g, '-');
      }
    }

    static getSelectedContentIndices(navData, sectionId, contentId = '', subContentId = '') {
        const sectionIndex = navData.findIndex(item => this.getAlias(item.section) === sectionId);
        if (sectionIndex > -1) {
          if (!contentId) {
            return null;
          }
          const itemIndex = navData[sectionIndex].items.findIndex(item => RouteProcessor.getAlias(item.text) === contentId);
          if (itemIndex > -1) {
            const indices = {
              sectionIndex: sectionIndex,
              itemIndex: itemIndex,
              versionIndex: -1,
              sublistIndex: -1
            };
            let sectionItem = navData[sectionIndex].items[itemIndex];
            if (subContentId) {
              if (sectionItem.versions?.length) {
                indices.versionIndex = 0;
                sectionItem = sectionItem.versions[0];
              }
              const sublistIndex = sectionItem.sublist.findIndex(subItem => RouteProcessor.getAlias(subItem.text) === subContentId);
              if (sublistIndex > -1) {
                indices.sublistIndex = sublistIndex;
                return indices;
              }
            } else {
              return indices;
            }
          }
        }
        return null;
    }
}
